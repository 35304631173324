document.addEventListener('DOMContentLoaded', () => {
    const menuToggle = document.getElementById('menuToggle');
    const navMenu = document.getElementById('navMenu');

    menuToggle.addEventListener('click', () => {
        navMenu.classList.toggle('hidden');
        menuToggle.classList.toggle('rotate-180');
    });

    // Close menu when clicking outside
    document.addEventListener('click', (event) => {
        if (!navMenu.contains(event.target) && !menuToggle.contains(event.target)) {
            navMenu.classList.add('hidden');
            menuToggle.classList.remove('rotate-180');
        }
    });

    // Close menu when window is resized to larger screen
    window.addEventListener('resize', () => {
        if (window.innerWidth >= 768) { // 768px is the 'md' breakpoint in Tailwind
            navMenu.classList.remove('hidden');
            menuToggle.classList.remove('rotate-180');
        } else {
            navMenu.classList.add('hidden');
        }
    });
});
